import axios from 'axios';
import authHeader from 'shared/authHeader';

class UserService {
	static getUser(userID) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/user/${userID}`, {
					headers: authHeader()
				});
				reslove(res.data);
			} catch (e) {
				console.error('Error getting user', e)
				reject(e)
			}
		})
	}

	static updatePassword(data) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.put(`${process.env.REACT_APP_URL}/api/mail/updatepasswordla`, data)
				reslove(res.data);
			} catch (e) {
				console.error('Error updating password', e)
				reject(e)
			}
		})
	}
	

}

export default UserService;
